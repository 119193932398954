import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useParams, Navigate } from 'react-router-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './pages/Dashboard';
import DataPage from './pages/DataPage';
import AirtimePage from './pages/AirtimePage';
import ProfilePage from './pages/Profile';
import ElectricPage from './pages/Electric';
import Histories from './pages/Histories';
import Sms from './pages/Sms';
import Cable from './pages/Cable';
import Exam from './pages/Exam';
import Pricing from './pages/Pricing';




const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/data" element={<DataPage />} />
        <Route path="/airtime" element={<AirtimePage />} />
        <Route path="/electric" element={<ElectricPage />} />
        <Route path="/histories" element={<Histories />} />
        <Route path="/sms" element={<Sms />} />
        <Route path="/cable" element={<Cable />} />
        <Route path="/exam" element={<Exam />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
